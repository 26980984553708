import { http, appNotification, util, liveDB, sentry } from '../core';
import qs from 'qs';
import PagedService from './paged-service';
import urlShortener from './url-shortener';
import moment from 'moment';
import {
  surveyService,
  clientGroupsService,
  clientHoursService,
  clientUsersService,
  clientMentionUsersService,
  clientService,
} from '.';

export interface TextMessage {
  twilio_number: number;
  number: string;
  message?: string;
  is_email?: boolean;
  email_subject?: string;
  outbound_email_address?: string;
  gif_url?: string;
  text_ninja?: boolean;
  media?: any;
  outbound_media?: any;
}

class LeadsService extends PagedService {
  async list({
    clientId,
    search,
    filters,
    sorting,
    vin,
    stockNumber,
    pageSize = 20,
    exclusive = false,
  }: any) {
    const multiClientQueue =
      typeof localStorage == 'object'
        ? !!localStorage.getItem('multiClientQueue')
        : false;
    try {
      const params = {} as any;

      if (search) {
        params.search = search;
      }

      // Include vin and stockNumber in the params if they are present
      if (vin) {
        params.vin = vin;
      }
      if (stockNumber) {
        params.stock_number = stockNumber; // API expects 'stock_number' for stock number
      }

      params.page_size = pageSize;

      if (sorting) {
        params.ordering =
          sorting === 'Newest First' ? '-last_message' : 'last_message';
      }

      //TODO: clean this up
      Object.keys(filters ?? {}).forEach((k: string) => {
        const value = filters[k];
        if (typeof value === 'object' && value.id !== 'Unassigned') {
          params[k] = value.id;
          return;
        }

        switch (k) {
          case 'human':
            if (value !== 'All') {
              if (value === 'Without') {
                params['uncalled'] = true;
              }
              params['last_human_outbound_user__isnull'] = value === 'Without';
              params['convo_archived'] = false;
              params['pause_date__isnull'] = true;
            }
            return;
          case 'pause_date':
            if (value !== 'All') {
              params['pause_date__isnull'] = value === 'Unpaused';
            }
            return;
          case 'needs_call':
            if (value !== 'All') {
              params['needs_call'] = value === 'Call Needed' || value === true;
            }
            return;
          case 'convo_archived':
            if (value !== 'All') {
              params['convo_archived'] = value === 'Archived';
            }
            return;
          case 'assigned_to':
            params['assigned_to__isnull'] = true;
            return;
          case 'created_at__gte':
          case 'created_at__lte':
          case 'last_human_inbound_time__gte':
          case 'last_human_inbound_time__lte':
          case 'last_message__gte':
          case 'last_message__lte':
          case 'last_lead_submitted_actual__gte':
          case 'last_lead_submitted_actual__lte':
            params[k] = k.match(/lte/i)
              ? moment(value).endOf('day').toISOString()
              : moment(value).startOf('day').toISOString();
            return;
        }

        if (value !== 'All') {
          params[k] = value;
        }
      });

      const query = qs.stringify(params);
      const url = multiClientQueue
        ? `/multi-client-inbound-queue/?${query}`
        : `/clients/${clientId}/list-leads/?${query}`;
      const res = await http.authorizedRequest({
        method: 'GET',
        url,
        exclusive,
        type: `${clientId}-list-leads`,
      });

      return res.data;
    } catch (e) {
      http.onHttpError(e);
      // Return a default response object with empty results to prevent undefined errors
      return { count: 0, results: [] };
    }
  }

  async listNeedsCall(opts: any) {
    const filters = {
      needs_call: true,
      convo_archived: false,
    };
    return await this.list({ ...opts, filters });
  }


  async loadNeedsCall(clientId: any) {
    try {
      const result = await this.listNeedsCall({ clientId, pageSize: 1 });
      const count = result?.count ?? 0;
      return {
        clientNeedsCallCount: count,
      };
    } catch (e) {
      sentry.capture(e as Error);
      return {
        clientNeedsCallCount: 0,
      };
    }
  }

  async listDeadGhostPipeline(opts: any) {
    const filters = {
      pause_date__isnull: true,
      status__in: '28',
      convo_archived: false,
      imported_replied: '2',
      lead_type__in: 'SMS,Web,Other,ADF,DMS',
      // Q(sms_violations=None) | Q(sms_violations__lte=settings.SMS_VIOLATIONS_MAX)
      violators: 'false',
      // Q(sms_opt_out=None) | Q(sms_opt_out__lte=8)
      sms_optout: 'false',
      // Q(carrier_type=None) | Q(carrier_type='mobile') | Q(carrier_type='voip')
      carrier_type__in: 'null,mobile,voip',
      classification: 'Sales',
    };
    return await this.list({ ...opts, filters });
  }

  async listWorkingPipeline(opts: any) {
    const filters = {
      pause_date__isnull: true,
      status__in: '1,2,23,21,11,12,35',
      convo_archived: false,
      no_blast: false,
      imported_replied: '2',
      lead_type__in: 'SMS,Web,Other,ADF,DMS',
      // Q(sms_violations=None) | Q(sms_violations__lte=settings.SMS_VIOLATIONS_MAX)
      violators: 'false',
      // Q(sms_opt_out=None) | Q(sms_opt_out__lte=8)
      sms_optout: 'false',
      // Q(carrier_type=None) | Q(carrier_type='mobile') | Q(carrier_type='voip')
      carrier_type__in: 'null,mobile,voip',
      classification: 'Sales',
    };
    return await this.list({ ...opts, filters });
  }

  async listPausedPipeline(opts: any) {
    const filters = {
      pause_date__isnull: false,
      convo_archived: false,
    };
    return await this.list({ ...opts, filters });
  }

  async listHumanInbounds(opts: any) {
    const day = opts?.date ?? new Date();
    const period = opts?.period ?? 'day';
    const filters = {
      last_human_inbound_time__gte: moment(day).startOf(period).toISOString(),
      last_human_inbound_time__lte: moment(day).endOf(period).toISOString(),
      convo_archived: false,
    };
    return await this.list({ ...opts, filters });
  }

  async listSubmitted(opts: any) {
    const day = opts?.date ?? new Date();
    const period = opts?.period ?? 'day';
    const filters = {
      last_lead_submitted_actual__gte: moment(day).startOf(period).toISOString(),
      last_lead_submitted_actual__lte: moment(day).endOf(period).toISOString(),
      convo_archived: false,
    };
    return await this.list({ ...opts, filters });
  }

  async listNewLeads(opts: any) {
    const day = opts?.date ?? new Date();
    const period = opts?.period ?? 'day';
    const filters = {
      created_at__gte: moment(day).startOf(period).toISOString(),
      created_at__lte: moment(day).endOf(period).toISOString(),
      convo_archived: false,
    };
    return await this.list({ ...opts, filters });
  }

  async listWaiting(opts: any) {
    const filters = {
      convo_archived: false,
      pause_date__isnull: true,
      last_message_type: 'inbound',
    };
    return await this.list({ ...opts, filters });
  }


  async loadWaiting(clientId: any) {
    try {
      const result = await this.listWaiting({ clientId, pageSize: 1 });
      const count = result?.count ?? 0;
      return {
        clientInboundCount: count,
      };
    } catch (e) {
      sentry.capture(e as Error);
      return {
        clientInboundCount: 0,
      };
    }
  }

  async getUnassignedCount(clientId: any) {
    try {
      const { count = 0 } = await this.list({
        clientId,
        filters: { assigned_to__isnull: true, convo_archived: false },
        pageSize: 1,
      });
      return count;
    } catch (e) {
      sentry.capture(e as Error);
      return 0;
    }
  }

  async getAssignedCount(clientId: any, user: number) {
    try {
      const { count = 0 } = await this.list({
        clientId,
        filters: { assigned_to: { id: user } },
        pageSize: 1,
      });
      return count;
    } catch (e) {
      sentry.capture(e as Error);
      return 0;
    }
  }

  async get(clientId: number | null | undefined, id: string | number) {
    const res = await http.authorizedRequest({
      method: 'GET',
      url: `/clients/${clientId}/leads/${id}/`,
    });

    return res.data;
  }

  async fetchAiSummary(clientId: number, leadId: number) {
    try {
      const res = await http.authorizedRequest({
        method: 'GET',
        url: `/clients/${clientId}/ai-summary/${leadId}/`,
      });
      return res.data;
    } catch (e) {
      http.onHttpError(e);
      // Return a default response object with empty results to prevent undefined errors
      return { count: 0, results: [] };
    }
  }

  async doRoundRobin(clientId: number, leadId: number) {
    try {
      const res = await http.authorizedRequest({
        method: 'POST',
        url: `/clients/${clientId}/do-round-robin/${leadId}/`,
      });
      return res.data;
    } catch (e) {
      http.onHttpError(e);
      // Return a default response object with empty results to prevent undefined errors
      return { count: 0, results: [] };
    }
  }

  async create(clientId: any, data: any) {
    try {
      data.phone_number = util.formatPhone(data.phone_number);
      const res = await http.authorizedRequest({
        method: 'POST',
        url: `/clients/${clientId}/leads/`,
        data,
      });

      return res.data;
    } catch (e) {
      http.onHttpError(e);
      // Return a default response object with empty results to prevent undefined errors
      return { count: 0, results: [] };
    }
  }

  async update(data: any, options?: any) {
    const { id, client } = data;
    if (data.phone_number) {
      data.phone_number = util.formatPhone(data.phone_number);
    }

    try {
      const res = await http.authorizedRequest({
        method: 'PATCH',
        url: `/clients/${client}/leads/${id}/`,
        data,
      });
      if (!options?.noToast) {
        appNotification.toast(
          options?.successMessage ?? 'Lead updated.',
          'Success'
        );
      }
      return res.data;
    } catch (e) {
      if (!options?.noToast) {
        http.onHttpError(e);
      } else {
        throw e;
      }
    }
  }

  async archive(clientId?: number, leadId?: number, options?: any) {
    return await this.update(
      {
        client: clientId,
        id: leadId,
        convo_archived: true,
        pause_date: null,
        pause_message: null,
      },
      options
    );
  }

  async unarchive(clientId?: number, leadId?: number, options?: any) {
    return await this.update(
      {
        client: clientId,
        id: leadId,
        convo_archived: false,
      },
      options
    );
  }

  async getMessages(
    clientId: number | null | undefined,
    id: string | number,
    page_size?: number,
    text_ninja?: boolean,
    notes_only?: boolean,
    is_call?: boolean,
    is_email?: boolean
  ) {
    const params = {
      page_size,
      text_ninja,
      notes_only,
      is_email,
      call__isnull: is_call ? false : undefined,
    };

    const query = qs.stringify(params);

    const { data } = await http.authorizedRequest({
      method: 'GET',
      url: `/clients/${clientId}/leads/${id}/messages/?${query}`,
    });

    if (!data.next && data.results.length) {
      data.results[data.results.length - 1].iframe_tag =
        await this.getAdPreview(id);
    }

    return data;
  }

  async getMessage(
    clientId: number | null | undefined,
    leadId: string | number,
    id: string | number
  ) {
    const { data } = await http.authorizedRequest({
      method: 'GET',
      url: `/clients/${clientId}/leads/${leadId}/messages/${id}/`,
    });
    return data;
  }

  async getCreditApps(
    clientId: number | null | undefined,
    id: string | number
  ) {
    try {
      const { data } = await http.authorizedRequest({
        method: 'GET',
        url: `/clients/${clientId}/leads/${id}/credit-applications/`,
      });

      return data;
    } catch (e) {
      return null;
    }
  }

  async getTradeIns(clientId: number | null | undefined, id: string | number) {
    try {
      const { data } = await http.authorizedRequest({
        method: 'GET',
        url: `/clients/${clientId}/leads/${id}/tradeins/`,
      });

      return data;
    } catch (e) {
      return null;
    }
  }

  async getInventoryLikes(
    clientId: number | null | undefined,
    id: string | number
  ) {
    try {
      const { data } = await http.authorizedRequest({
        method: 'GET',
        url: `/clients/${clientId}/leads/${id}/inventory-likes/`,
      });

      return data;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async setAppointmentStatus(
    client: any,
    id: any,
    key: string,
    appointment: any
  ) {
    const updates = {
      showed: appointment.showed,
      showed_lost: null,
      sold: null,
      cancelled: null,
      missed: null,
    } as any;
    updates[key] = new Date().toISOString();

    if (key === 'set') {
      updates['showed'] = null;
      updates['showed_lost'] = null;
      updates['sold'] = null;
      updates['missed'] = null;
      updates['cancelled'] = null;
    }

    if (key === 'showed_lost') {
      updates['showed'] = new Date().toISOString();
    }

    if (key === 'sold' && !appointment.showed) {
      updates['showed'] = new Date().toISOString();
    }

    Object.assign(appointment, updates);

    return await this.changeAppointment(client, id, appointment.id, updates);
  }

  private async updateAppointmentStatus(
    client: any,
    id: any,
    appointment: any,
    key: string,
    status: number
  ) {
    const [l, a] = await Promise.all([
      this.update(
        {
          client,
          id,
          status,
        },
        { noToast: true }
      ),
      this.setAppointmentStatus(client, id, key, appointment),
    ]);
    return {
      lead: l,
      appt: a,
    };
  }

  async changeAppointment(
    clientId: any,
    id: any,
    appointmentId: any,
    updates: any
  ) {
    try {
      const { data } = await http.authorizedRequest({
        method: 'PATCH',
        url: `/clients/${clientId}/leads/${id}/appointments/${appointmentId}/`,
        data: updates,
      });

      return data;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async updateAppointmentTime(
    clientId: any,
    id: any,
    appointmentId: any,
    updates: any
  ) {
    try {
      const appt = await this.changeAppointment(
        clientId,
        id,
        appointmentId,
        updates
      );
      const lead = await this.update(
        {
          id,
          client: clientId,
          status: 9,
        },
        { noToast: true }
      );
      return { lead, appt };
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async appointmentSet(client: number, id: number, appointment: any) {
    return await this.updateAppointmentStatus(
      client,
      id,
      appointment,
      'set',
      9
    );
  }

  async appointmentMissed(client: number, id: number, appointment: any) {
    return await this.updateAppointmentStatus(
      client,
      id,
      appointment,
      'missed',
      11
    );
  }
  async appointmentShowed(client: number, id: number, appointment: any) {
    return await this.updateAppointmentStatus(
      client,
      id,
      appointment,
      'showed',
      10
    );
  }

  async appointmentShowedLost(client: number, id: number, appointment: any) {
    return await this.updateAppointmentStatus(
      client,
      id,
      appointment,
      'showed_lost',
      40
    );
  }

  async appointmentSold(
    client: number,
    id: number,
    appointment: any,
    status: number
  ) {
    return await this.updateAppointmentStatus(
      client,
      id,
      appointment,
      'sold',
      status
    );
  }

  async cancelAppointment(client: number, id: number, appointment: any) {
    return await this.updateAppointmentStatus(
      client,
      id,
      appointment,
      'cancelled',
      12
    );
  }

  async createAppointment(
    clientId: number | null | undefined,
    id: string | number,
    appointment_time: string
  ) {
    try {
      const { data } = await http.authorizedRequest({
        method: 'POST',
        url: `/clients/${clientId}/leads/${id}/appointments/`,
        data: {
          appointment_time,
        },
      });

      const lead = await this.update(
        {
          id,
          client: clientId,
          status: 9,
        },
        { noToast: true }
      );

      return { lead, appt: data };
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async sendMessage(
    clientId: string,
    leadId: string,
    message: TextMessage,
    onUploadProgress?: (pct: number) => void
  ) {
    const data = new FormData();
    const msg = Object.assign({ sms_type: 'outbound' }, message) as any;

    for (var key in msg) {
      const value = msg[key];
      if (value !== undefined && value !== null) {
        data.append(key, value);
      }
    }

    return await http.authorizedRequest({
      method: 'POST',
      url: `/clients/${clientId}/leads/${leadId}/messages/`,
      data,
      onUploadProgress,
    });
  }

  isSupportedFileType(type: string, isEmail: boolean = false): boolean {
    // If it's meant for email, check against a wider list
    if (isEmail) {
      const emailAttachmentRegex =
        /^(image|video)\/|application\/(pdf|msword|vnd\.openxmlformats-officedocument|vnd\.ms-excel|vnd\.ms-powerpoint|zip|rtf|csv)|text\/plain$/i;
      return !!type.match(emailAttachmentRegex);
    }
    return util.isVideoOrImage(type) || util.isURI(type);
  }

  async getAdPreview(leadId: string | number) {
    try {
      const res = await http.authorizedRequest({
        url: `/facebook-ad/preview/${leadId}/`,
      });

      return res.data.iframe_tag;
    } catch (e) {
      console.error(e);
    }
  }

  private formatAddressString(str: string): string {
    return str.replace(/\s{2,}|,\s+(?!\w)/g, '').trim();
  }

  getLeadClientAddressFormatted(lead: any) {
    const {
      client_address,
      client_address_2,
      client_city,
      client_state,
      client_postal_code,
    } = lead;

    return this.formatAddressString(
      `${client_address ?? ''}, ${client_address_2 ?? ''}, ${
        client_city ?? ''
      }, ${client_state ?? ''}, ${client_postal_code ?? ''}`
    );
  }

  leadHasAddress(lead: any) {
    const fields = ['address', 'address_2', 'city', 'state', 'postal_code'];
    return fields.some((field) => !!lead?.[field]);
  }

  getLeadAddressFormatted(lead: any) {
    if (!this.leadHasAddress(lead)) {
      return '';
    }

    const { address, address_2, city, state, postal_code } = lead;

    return this.formatAddressString(
      `${address ?? ''}, ${address_2 ?? ''}, ${city ?? ''}, ${state ?? ''}, ${
        postal_code ?? ''
      }`
    );
  }

  async getLeadClientAddressLink(lead: any) {
    const clientAddress = this.getLeadClientAddressFormatted(lead);
    const leadAddress =
      this.getLeadAddressFormatted(lead) || 'Current Location';
    const url = `https://www.google.com/maps/dir/${encodeURIComponent(
      leadAddress
    )}/${encodeURIComponent(clientAddress)}`;
    const { short_url } = await urlShortener.shorten(url);
    return short_url || url;
  }

  async removeInventoryLike(clientId: number, leadId: number, id: number) {
    try {
      const res = await http.authorizedRequest({
        method: 'DELETE',
        url: `/clients/${clientId}/leads/${leadId}/inventory-likes/${id}/`,
      });

      return res;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async addInventoryLike(clientId: number, leadId: number, id: number) {
    try {
      const res = await http.authorizedRequest({
        method: 'POST',
        url: `/clients/${clientId}/leads/${leadId}/inventory-likes/`,
        data: {
          inventory: id,
        },
      });

      return res;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  createLeadReference(leadId: number) {
    return liveDB.useReference(`/sms/${leadId}/`);
  }

  async sendToCRM(clientId: any, leadId: any) {
    try {
      const res = await http.authorizedRequest({
        method: 'POST',
        url: `/clients/${clientId}/push-lead-to-crm/${leadId}/`,
      });

      return res;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async mergeDuplicates(lead: number) {
    try {
      const res = await http.authorizedRequest({
        method: 'POST',
        url: `/crm/merge-duplicate-leads/`,
        data: {
          lead,
        },
      });

      return res;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  async getCountWithoutHumanInteraction(clientId: any) {
    const filters = {
      human: 'Without',
    };
    const { count } = await this.list({ clientId, filters, pageSize: 1 });
    return count;
  }

  async reassignLeads(clientId: any, fromId: number | null, toId?: number | null, toIds?: number[]) {
    const data: any = {
      from_user: fromId,
    }

    if (toId === null) {
      data.to_user = null;
    } else if (toIds && toIds.length > 0) {
      data.to_users = toIds;
    }

    return await http.authorizedRequest({
      method: 'POST',
      url: `/clients/${clientId}/reassign-leads/`,
      data,
    });
  }

  getTimezone(lead: any) {
    return util.formattedTimezone(lead?.client_timezone);
  }

  async loadByToken(hash: string) {
    try {
      const { data } = await http.request({
        method: 'GET',
        url: `/lead/${hash}/`,
      });

      return data;
    } catch (e) {
      http.onHttpError(e);
    }
  }

  getLink(clientId: any, leadId: any, isInbound: any, isNeedsCall = false) {
    return `/${
      isNeedsCall ? 'needs-call' : isInbound ? 'inbound-queue' : 'text-messages'
    }/conversation/${clientId}/${leadId}/`;
  }

  async loadConversationData(
    clientId: any,
    leadId: any,
    selectedClientId: any,
    internalMessages?: boolean,
    notesOnly?: boolean,
    callsOnly?: boolean,
    emailsOnly?: boolean
  ) {
    const reqs: any[] = [
      this.get(clientId, leadId),
      this.getMessages(
        clientId,
        leadId,
        undefined,
        internalMessages,
        notesOnly,
        callsOnly,
        emailsOnly
      ),
      surveyService.listResults(clientId, leadId),
    ];

    if (clientId?.toString() !== selectedClientId?.toString()) {
      reqs.push(
        ...[
          clientUsersService.request(clientId),
          clientGroupsService.request(clientId),
          clientHoursService.request(clientId),
          clientMentionUsersService.request(clientId),
          clientService.request(clientId),
        ]
      );
    }

    return await Promise.all(reqs);
  }

  checkForPauseResets(lead: any, thread: any) {
    if (!lead.pause_date) {
      const tz = this.getTimezone(lead);
  
      const pauseRegex = /(?:Needs Call Set;\s*)?Pause Date(?:\s+(\d{2}\/\d{2}\/\d{2} \d{1,2}:\d{2}(?:am|pm) [A-Z]+))?;?/i;
      const messageRegex = /Pause Message:\s*(.+)/is;
  
      thread.results?.forEach?.((msg: any) => {
        const pauseMatch = msg.message?.match?.(pauseRegex);
        if (pauseMatch) {
          const hasNeedsCall = /Needs Call Set;/i.test(msg.message);
          const dateString = pauseMatch[1];
          const date = moment.tz(dateString, 'MM/DD/YY hh:mma z', tz);
  
          if (date.isAfter(moment().tz(tz))) {
            const msgMatch = msg.message?.match?.(messageRegex);
            const pauseMsg = msgMatch?.[1]?.trim() || null;
  
            msg.resetPause = {
              needsCall: hasNeedsCall,
              date,
              message: pauseMsg,
            };
          }
        }
      });
    }
  }
}

const leadsService = new LeadsService();
export default leadsService;
