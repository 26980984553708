import { useEffect, useState, useRef, useCallback } from "react";
import {
  leadsService,
  archivedLeadsService,
  tradeinStatsService,
  creditAppStatsService,
  quickQualificationsService,
} from "../services";
import moment from "moment";
import { SetAppointment } from "../types/Appointments";

type Period = "day" | "month";

export default function usePeriodCardData(
  clientId: number | undefined,
  leadAssignedTo: number | undefined,
  date: Date,
  period: Period = "day"
) {
  const [loading, setLoading] = useState(true);
  const [newLeads, setNewLeads] = useState<any>();
  const [submittedLeads, setSubmittedLeads] = useState<any>();
  const [inboundLeads, setInboundLeads] = useState<any>();
  const [archivedLeads, setArchivedLeads] = useState<any>();
  const [tradeins, setTradeins] = useState<SetAppointment[]>([]);
  const [creditApps, setCreditApps] = useState<SetAppointment[]>([]);
  const [softPulls, setSoftPulls] = useState<number>(0);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const clientIdRef = useRef(clientId);
  const leadAssignedToRef = useRef(leadAssignedTo);
  const dateRef = useRef(date);

  useEffect(() => {
    clientIdRef.current = clientId;
    leadAssignedToRef.current = leadAssignedTo;
    dateRef.current = date;
  }, [clientId, leadAssignedTo, date]);

  const fetchData = useCallback(async () => {
    const currentClientId = clientIdRef.current;
    const currentLeadAssignedTo = leadAssignedToRef.current;
    const currentDate = dateRef.current;

    if (!currentClientId) return;
    setLoading(true);

    const start = moment(currentDate).startOf(period).toISOString();
    const end = moment(currentDate).endOf(period).toISOString();

    const [
      newLeadsRes,
      submittedRes,
      inboundsRes,
      archivedRes,
      tradeinsRes,
      creditAppsRes,
      softPullsRes,
    ] = await Promise.all([
      leadsService.listNewLeads({ clientId: currentClientId, pageSize: 1, date: currentDate, period }),
      leadsService.listSubmitted({ clientId: currentClientId, pageSize: 1, date: currentDate, period }),
      leadsService.listHumanInbounds({ clientId: currentClientId, pageSize: 1, date: currentDate, period }),
      archivedLeadsService.listArchivedLeads(currentClientId, currentDate, period),
      tradeinStatsService.list({
        client_id: currentClientId,
        lead__assigned_to: currentLeadAssignedTo,
        created_at__gte: start,
        created_at__lte: end,
        trade_pending_id__isnull: false,
        distinct: true,
      }),
      creditAppStatsService.list({
        client_id: currentClientId,
        lead__assigned_to: currentLeadAssignedTo,
        completed_at__gte: start,
        completed_at__lte: end,
        distinct: true,
      }),
      quickQualificationsService.list({
        clientId: currentClientId,
        filters: {
          lead__assigned_to: currentLeadAssignedTo,
          created_at__gte: start,
          created_at__lte: end,
          distinct: true,
        },
      }),
    ]);

    setNewLeads(newLeadsRes);
    setSubmittedLeads(submittedRes);
    setInboundLeads(inboundsRes);
    setArchivedLeads(archivedRes);
    setTradeins(tradeinsRes);
    setCreditApps(creditAppsRes);
    setSoftPulls(softPullsRes?.count || 0);
    setLoading(false);
  }, [period]);

  const startPolling = useCallback(() => {
    fetchData();
    if (intervalRef.current) clearInterval(intervalRef.current);
    intervalRef.current = setInterval(fetchData, 60000);
  }, [fetchData]);

  const stopPolling = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  }, []);

  useEffect(() => {
    return () => {
      stopPolling(); // Clean up just in case
    };
  }, [stopPolling]);

  return {
    loading,
    newLeads,
    submittedLeads,
    inboundLeads,
    archivedLeads,
    tradeins,
    creditApps,
    softPulls,
    startPolling,
    stopPolling,
    refetch: fetchData,
  };
}
