import { Plugins } from '@capacitor/core';
import { isPlatform } from '@ionic/core';
import { menuController } from '@ionic/core/components';
import copy from 'clipboard-copy';
import appNotification from './app-notifications';
import native from './native';
import moment from 'moment';
import MD5 from 'crypto-js/md5';
import Base64 from 'crypto-js/enc-base64';
import Utf8 from 'crypto-js/enc-utf8';
import numeral from 'numeral';
import {v4 as uuidv4} from 'uuid';
import { AppPage } from '../pages';
import { User } from '../types/User';
import { createTheme } from 'react-data-table-component';
import { readAndCompressImage } from 'browser-image-resizer';
import { Clipboard } from '@capacitor/clipboard';

const { App } = Plugins;

class Util {
  async copyToClipboard(str: string, successText?: any) {
    if (isPlatform('capacitor')) {
      await Clipboard.write({
        string: str
      });
    } else {
      await copy(str);
    }

    if (successText !== false) {
      appNotification.toast(successText || 'Copied to clipboard', 'Copied');
    }
  }

  setTitle(title?: string) {
    document.title = `TECOBI${title ? ' - ' : ''}${title || ''}`;
  }

  defer(fn: Function) {
    this.delay(fn, 0);
  }

  delay(fn: Function, ms: number) {
    setTimeout(fn, ms);
  }

  stringCompare(one: string, two: string, desc?: boolean) {
    return desc ? two.localeCompare(one) : one.localeCompare(two);
  }

  numberCompare(one: number, two: number, desc?: boolean) {
    return desc ? two - one : one - two;
  }

  sortByProp(arr: Array<any>, prop: string, desc?: boolean) {
    return arr?.sort?.((a: any, b: any) => {
      const one = a[prop];
      const two = b[prop];

      if (typeof one == 'number') {
        return this.numberCompare(one, two, desc);
      }

      return this.stringCompare(
        (one || '') as string,
        (two || '') as string,
        desc
      );
    });
  }

  async resizeImage(file: File) {
    const config = {
      maxWidth: 1024, // specify the max width you want
      maxHeight: 1024, // specify the max height you want
      quality: 0.9,   // JPEG compression quality
      autoRotate: false,
      debug: true
    };
    return readAndCompressImage(file, config);  // use readAndCompressImage here
  }

  async showLeadSidebar() {
    const menu = await menuController.get('lead');
    if (menu) {
      menu.open();
    }
  }

  async hideLeadSidebar() {
    const menu = await menuController.get('lead');
    if (menu) {
      menu.close();
    }
  }

  get(o: any, path: string[] | string, value?: any) {
    const pathArray = Array.isArray(path)
      ? path
      : path.split('.').filter(key => key);
    const pathArrayFlat = pathArray.flatMap(part =>
      typeof part === 'string' ? part.split('.') : part
    );

    return pathArrayFlat.reduce((obj, key) => obj && obj[key], o) || value;
  }

  isVideoOrImage(type: string): boolean {
    return !!type.match(/^(image|video)\//i);
  }

  getFacebookAdStatusBadgeColor(status: string) {
    if (status.toLowerCase().indexOf('active') > -1) {
      return 'success';
    }
    if (status.toLowerCase().indexOf('pause') > -1) {
      return 'warning';
    }
    if (status.toLowerCase().indexOf('archived') > -1) {
      return 'medium';
    }
    return 'danger';
  }

  async getVideoPoster(src: string): Promise<string> {
    return new Promise(resolve => {
      let video = document.createElement('video');
      video.src = src;
      video.onseeked = () => {
        let canvas = document.createElement('canvas');
        let context = canvas.getContext('2d');
        let [w, h] = [video.videoWidth, video.videoHeight];
        canvas.width = w;
        canvas.height = h;

        if (context) {
          context.drawImage(video, 0, 0, w, h);
        }

        try {
          const url = canvas.toDataURL();
          resolve(url);
        } catch (e) {
          // resolve();
        }
      };

      video.onloadeddata = () => {
        if (video.readyState >= 3) {
          video.currentTime = Math.min(1, video.duration);
        }
      };
      video.load();
    });
  }

  chooseFiles(onSelection: (files:FileList) => any, restrictedTypes?: string) {
    const id = 'custom-files-picker';
    const existing = document.getElementById(id);
    if (existing) {
      document.body.removeChild(existing);
    }
    const element = document.createElement('input');
    element.type = 'file';
    if (restrictedTypes) {
      element.accept = restrictedTypes;
    }
    element.multiple = true;
    element.id = id;
    element.style.position = 'absolute';
    element.style.top = '-100%';
    element.style.opacity = '0';
    element.onchange = async (e: any) => {
      await onSelection(e.target.files);
      document.body.removeChild(element);
    };
    document.body.appendChild(element);
    element.click();
  }

  chooseFile(onSelection: (file: any) => any, restrictedTypes?: string) {
    const id = 'custom-file-picker';
    const existing = document.getElementById(id);
    if (existing) {
      document.body.removeChild(existing);
    }
    const element = document.createElement('input');
    element.type = 'file';
    if (restrictedTypes) {
      element.accept = restrictedTypes;
    }
    element.id = id;
    element.style.position = 'absolute';
    element.style.top = '-100%';
    element.style.opacity = '0';
    element.onchange = async (e: any) => {
      await onSelection(e.target.files[0]);
      document.body.removeChild(element);
    };
    document.body.appendChild(element);
    element.click();
  }

  getUsername(user: User): string | null | undefined {
    return user.email.split('@')[0];
  }

  isEnterKey(e: any): boolean {
    return e?.which === 13;
  }

  isTabKey(e: any): boolean {
    return e?.which === 9;
  }

  isModifierEnter(e: any): boolean {
    return this.isEnterKey(e) && (e.ctrlKey || e.metaKey || e.shiftKey);
  }

  isNativeOrModifierEnter(e: any): boolean {
    return this.isModifierEnter(e) || (this.isEnterKey(e) && native.isNative);
  }

  formatMessageDate(str: string) {
    return moment(str).format('hh:mma');
  }

  gravatarUrl(email: string, fallback: string = 'mp') {
    return `https://www.gravatar.com/avatar/${MD5(email)}?d=${fallback}`;
  }

  initials(name: string): string {
    var initials = name.match(/\b\w/g) || [];
    return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
  }

  randomItem(items: any[]) {
    return items[Math.floor(Math.random() * items.length)];
  }

  arraybufferToBase64(data: any) {
    return btoa(
      new Uint8Array(data).reduce(
        (data, byte) => data + String.fromCharCode(byte),
        ''
      )
    );
  }

  composeEmail(email: string) {}

  formatDollar(n: number) {
    return numeral(n).format('$0,0');
  }

  formatNumber(n: number) {
    return numeral(n).format('0,0');
  }

  ymmt(item: any) {
    const { year, make, model, trim } = item;
    return [year, make, model, trim].join(' ');
  }

  getSelectedPage(location: any, pages: AppPage[]) {
    const allPages = pages.reduce((arr: any, page: AppPage) => {
      arr.push(page);
      if (page.subpages) {
        arr.push(...page.subpages);
      }
      return arr;
    }, [] as any);

    const matches = (it: any) => {
      if (!it.url) return false;

      if (location.pathname === '/') {
        return it.url === '/';
      }

      return it.url !== '/' && location?.pathname?.match(new RegExp(it.url));
    };

    return allPages.find(matches);
  }

  getParentPage(page: AppPage, pages: AppPage[]) {
    return pages.find((it: any) => it.subpages?.indexOf?.(page) > -1);
  }

  getPluralizedString(str: string, count: number) {
    return `${str}${count !== 1 ? 's' : ''}`;
  }

  moveCursorToEnd(element: any) {
    if (!element) {
      return;
    }

    if (typeof element.selectionStart == 'number') {
      element.selectionStart = element.selectionEnd = element.value.length;
    } else if (typeof element.createTextRange != 'undefined') {
      var range = element.createTextRange();
      range.collapse(false);
      range.select();
    }
  }

  dataURItoFile(dataURI: string): Blob {
    var byteString = atob(dataURI.split(',')[1]);

    var mimeString = dataURI
      .split(',')[0]
      .split(':')[1]
      .split(';')[0];

    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([ab], { type: mimeString });
    const format = mimeString.split('/')[1];

    return new File([blob], `media.${format}`, {
      lastModified: Date.now(),
      type: mimeString
    });
  }

  timeStringToHoursMinutesAndSeconds(str: string, defaultValue = '00:00:00') {
    if (!str) {
      return defaultValue;
    }
    const parts = str.replace(/\..*/, '').split(':');
    return `${parts[0]}:${parts[1]}:${parts[2]}`;
  }

  async urlToDataUrl(url: string) {
    const blob = await fetch(url).then(r => r.blob());
    return await new Promise(resolve => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  createTecobiDarkDataTableTheme() {
      return createTheme('tecobi-dark', {
        text: {
          primary: '#FFFFFF',
          secondary: 'rgba(255, 255, 255, 0.7)',
          disabled: 'rgba(0,0,0,.12)',
        },
        background: {
          default: '#1a1b1e',
        },
        context: {
          background: '#E91E63',
          text: '#FFFFFF',
        },
        divider: {
          default: 'rgba(81, 81, 81, 1)',
        },
        button: {
          default: '#FFFFFF',
          focus: 'rgba(255, 255, 255, .54)',
          hover: 'rgba(255, 255, 255, .12)',
          disabled: 'rgba(255, 255, 255, .18)',
        },
        sortFocus: {
          default: 'rgba(255, 255, 255, .54)',
        },
        selected: {
          default: 'rgba(0, 0, 0, .7)',
          text: '#FFFFFF',
        },
        highlightOnHover: {
          default: 'rgba(0, 0, 0, .7)',
          text: '#FFFFFF',
        },
        striped: {
          default: 'rgba(0, 0, 0, .87)',
          text: '#FFFFFF',
        },
      });
  }

  uuid() {
    return uuidv4();
  }

  formatPhone(phone: string) {
    if (!phone) {
      return phone;
    }
    return `+${phone.replace(/\D/g, '')}`;
  }

  formatHumanReadablePhone(phoneNumberString: string) {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return '';
  }

  getIdIndexMap(arr: any[], key: string) {
    return arr?.reduce?.((obj: any, it: any, i: number) => {
      obj[it[key]] = i;
      return obj;
    }, {});
  }

  stringToDataURI(data: string, mimeType: string) {
    return `data:${mimeType};base64,${Base64.stringify(Utf8.parse(data))}`;
  }

  locationIsNeedsCall(location: any) {
    return !!location?.pathname?.match(/^\/needs-call\/conversation/i);
  }

  locationIsInbound(location: any) {
    return !!location?.pathname?.match(/^\/inbound-queue\/conversation/i);
  }

  regexpEscape(s: string) {
    return s.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
  }

  extractUrls(s: string) {
    return s?.match(
      /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$])/gim
    );
  }

  downloadDataURI(uri: string, filename: string) {
    const encodedUri = encodeURI(uri);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    setTimeout(() => {
      document.body.removeChild(link);
    }, 1000);
  }

  downloadCSV(arr: any, filename: string, keys?: any[]) {
    if (!arr) {
      throw new Error('Object must not be undefined or null');
    }

    if (!keys) {
      keys = Object.keys(arr?.[0]);
    }

    const data = `${keys.join(',')}\n${arr
      .map((o: any) => Object.values(o).join(','))
      .join('\n')}`;

    const dataURI = `data:text/csv;charset=utf-8,${data}`;
    this.downloadDataURI(dataURI, filename);
  }

  calculateTotal(arr: {}[], field: string) {
    return arr.reduce((acc: number, el: any) => {
      acc += el[field];
      return acc;
    }, 0);
  }

  isURI(type: string) {
    return type === 'text/uri-list';
  }

  userIsStaff(user?: User) {
    return (
      !!user && (user.is_staff === true || user.is_admin || user.is_text_ninja)
    );
  }

  formattedTimezone(tz: string) {
    return tz?.replace?.(/\s+/g, '_') ?? 'GMT';
  }

  timezoneMoment(iso: string, tz: string) {
    return moment(iso).tz(this.formattedTimezone(tz));
  }

  cancelEvent(e: any) {
    e?.preventDefault();
    e?.stopPropagation();
  }

  tryParseInt(str: any) {
    try {
      const r = parseInt(str);
      return isNaN(r) ? null : r;
    } catch (e) {
      console.info(e);
      return null;
    }
  }

  appOpen(url: string) {
    native.isNative ? App.openUrl({ url }) : (window.location.href = url);
  }

  tel(number: any) {
    this.appOpen(`tel:${number}`);
  }

  sms(number: any, body = '') {
    this.appOpen(`sms:${number}&body=${encodeURIComponent(body)}`);
  }

  formatPhoneNumber(phoneNumberString: string) {
    const cleaned = ("" + phoneNumberString).replace(/\D/g, ""); // remove all non-numeric characters
  
    // Try to match the 11-digit format first
    let match = cleaned.match(/^1(\d{3})(\d{3})(\d{4})$/); // 1, followed by groups of 3, 3, and 4 digits
    if (!match) {
      // If no match, try the 10-digit format
      match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    }
  
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return phoneNumberString; // return original string if no match
  }
}

const util = new Util();
export default util;
