import React from 'react';
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonItem,
  IonLabel,
  IonSkeletonText,
  IonList,
  IonBadge,
  RouterDirection,
  IonButton,
  IonIcon,
} from '@ionic/react';
import { chevronBackOutline, chevronForwardOutline } from "ionicons/icons";

interface RowProps {
  label: string;
  value: number;
  handleClick?: () => void;
  routerLink?: string;
  routerDirection?: string;
  animated?: boolean;
};

interface DashboardCardProps {
  title: string;
  rows: RowProps[];
  loading: boolean;
  color?: string;
  goBack?: () => void;
  goForward?: () => void;
};

const DashboardCard: React.FC<DashboardCardProps> = ({
  title,
  rows,
  loading,
  color = 'tertiary',
  goBack,
  goForward
}) => {
  return (
    <IonCard>
      <IonCardHeader
        color={color}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row'
        }}
      >
        <div style={{ width: '50px' }}>
          {goBack && (
            <IonButton onClick={goBack} fill='clear' style={{ color: 'inherit' }}>
              <IonIcon icon={chevronBackOutline} />
            </IonButton>
          )}
        </div>
        <IonCardTitle>{title}</IonCardTitle>
        <div style={{ width: '50px' }}>
          {goForward && (
            <IonButton onClick={goForward} fill='clear' style={{ color: 'inherit' }}>
              <IonIcon icon={chevronForwardOutline} />
            </IonButton>
          )}
        </div>
      </IonCardHeader>
      <IonCardContent>
        {loading ? (
          Array.from(Array(rows.length).keys()).map((it: any, i: number) => (
            <IonItem key={i}>
              <IonLabel>
                <IonSkeletonText key={i} animated />
              </IonLabel>
            </IonItem>
          ))
        ) : (
          <div style={{ maxHeight: 365, overflow: "auto" }}>
            <IonList>
              {rows.map((row) => (
                <IonItem
                  key={row.label}
                  className={row.handleClick || row.routerLink ? 'pointer' : 'default'}
                  routerLink={row.routerLink}
                  routerDirection={row.routerDirection as RouterDirection || 'none'}
                  onClick={row.handleClick}
                  detail
                >
                  {row.label}
                  <IonBadge color={color} slot='end'>
                    {row.value}
                  </IonBadge>
                </IonItem>
              ))}
            </IonList>
          </div>
        )}
      </IonCardContent>
    </IonCard>
  )
};

export default DashboardCard;