import { Dispatch } from "react";

export function getTodayCardConfig(
  {
    date,
    isToday,
    loading,
    newLeadsToday,
    newSubmittedLeadsToday,
    newInboundsToday,
    archivedLeadsToday,
    creditAppsToday,
    softPullsToday,
    tradeinsToday,
    decrementDate,
    incrementDate,
    dispatch,
  }: {
    date: Date;
    isToday: boolean;
    loading: boolean;
    newLeadsToday: any;
    newSubmittedLeadsToday: any;
    newInboundsToday: any;
    archivedLeadsToday: any;
    creditAppsToday: any[];
    softPullsToday: number;
    tradeinsToday: any[];
    decrementDate: () => void;
    incrementDate: () => void;
    dispatch: Dispatch<any>;
  }
) {
  return {
    title: isToday
      ? "Today"
      : date.toLocaleDateString(undefined, {
          weekday: "short",
          month: "short",
          day: "numeric",
          year: "numeric",
        }),
    loading,
    color: "tertiary",
    goBack: decrementDate,
    goForward: !isToday ? incrementDate : undefined,
    rows: [
      {
        label: "New Leads",
        value: newLeadsToday?.count,
        routerLink: "/text-messages/",
        handleClick: () => {
          dispatch({
            type: "set",
            value: {
              filters: {
                convo_archived: "Active",
                created_at__gte: new Date(date).toISOString(),
                created_at__lte: new Date(date).toISOString(),
              },
            },
          });
        },
      },
      {
        label: "Leads Submitted",
        value: newSubmittedLeadsToday?.count,
        routerLink: "/text-messages/",
        handleClick: () => {
          dispatch({
            type: "set",
            value: {
              filters: {
                convo_archived: "Active",
                last_lead_submitted_actual__gte: new Date(date).toISOString(),
                last_lead_submitted_actual__lte: new Date(date).toISOString(),
              },
            },
          });
        },
      },
      {
        label: "Engaged Leads",
        value: newInboundsToday?.count,
        routerLink: "/text-messages/",
        handleClick: () => {
          dispatch({
            type: "set",
            value: {
              filters: {
                convo_archived: "Active",
                last_human_inbound_time__gte: new Date(date).toISOString(),
                last_human_inbound_time__lte: new Date(date).toISOString(),
              },
            },
          });
        },
      },
      {
        label: "Archived Leads",
        value: archivedLeadsToday?.length,
        routerLink: "/reports/archived-leads/",
      },
      {
        label: "Credit Apps",
        value: creditAppsToday.length,
        routerLink: "/credit-applications/",
      },
      {
        label: "Soft Pulls",
        value: softPullsToday,
        routerLink: "/quick-qualifications/",
      },
      {
        label: "Trade-Ins",
        value: tradeinsToday.length,
        routerLink: "/trades/",
      },
    ],
  };
}

export function getPipelineCardConfig(
  {
    loading,
    workingPipeline,
    pausedPipeline,
    deadGhostPipeline,
    dispatch,
  }: {
    loading: boolean;
    workingPipeline: any;
    pausedPipeline: any;
    deadGhostPipeline: any;
    dispatch: Dispatch<any>;
  }
) {
  return {
    title: "Pipelines",
    loading,
    color: "success",
    rows: [
      {
        label: "Auto Bot Working",
        value: workingPipeline?.count,
        routerLink: "/text-messages/",
        handleClick: () => {
          dispatch({
            type: "set",
            value: {
              filters: {
                convo_archived: "Active",
                lead_type__in: "SMS,Web,Other,ADF,DMS",
                classification: "Sales",
                pause_date: "Unpaused",
                imported_replied: "2",
                violators: false,
                sms_optout: false,
                carrier_type__in: "null,mobile,voip",
                status__in: "1,2,23,21,11,12,35",
              },
            },
          });
        },
      },
      {
        label: "Auto Bot Ghost",
        value: deadGhostPipeline?.count,
        routerLink: "/text-messages/",
        handleClick: () => {
          dispatch({
            type: "set",
            value: {
              filters: {
                convo_archived: "Active",
                lead_type__in: "SMS,Web,Other,ADF,DMS",
                classification: "Sales",
                pause_date: "Unpaused",
                imported_replied: "2",
                violators: false,
                sms_optout: false,
                carrier_type__in: "null,mobile,voip",
                status__in: "28",
              },
            },
          });
        },
      },
      {
        label: "Paused",
        value: pausedPipeline?.count,
        routerLink: "/text-messages/",
        handleClick: () => {
          dispatch({
            type: "set",
            value: {
              filters: {
                convo_archived: "Active",
                pause_date: "Paused",
              },
            },
          });
        },
      },
    ],
  };
}

export function getMonthCardConfig(
  {
    date,
    isCurrentMonth,
    loading,
    newLeadsThisMonth,
    newSubmittedThisMonth,
    newInboundsThisMonth,
    archivedLeadsThisMonth,
    creditAppsThisMonth,
    softPullsThisMonth,
    tradeinsThisMonth,
    decrementMonth,
    incrementMonth,
    dispatch,
  }: {
    date: Date;
    isCurrentMonth: boolean;
    loading: boolean;
    newLeadsThisMonth: any;
    newSubmittedThisMonth: any;
    newInboundsThisMonth: any;
    archivedLeadsThisMonth: any;
    creditAppsThisMonth: any[];
    softPullsThisMonth: number;
    tradeinsThisMonth: any[];
    decrementMonth: () => void;
    incrementMonth: () => void;
    dispatch: Dispatch<any>;
  }
) {
  const monthStart = new Date(date.getFullYear(), date.getMonth(), 1);
  const monthEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59, 999);

  return {
    title: isCurrentMonth ? 'This Month' : date.toLocaleDateString(undefined, {
      month: "long",
      year: "numeric",
    }),
    loading,
    color: "primary",
    goBack: decrementMonth,
    goForward: !isCurrentMonth ? incrementMonth : undefined,
    rows: [
      {
        label: "New Leads",
        value: newLeadsThisMonth?.count,
        routerLink: "/text-messages/",
        handleClick: () => {
          dispatch({
            type: "set",
            value: {
              filters: {
                convo_archived: "Active",
                created_at__gte: monthStart.toISOString(),
                created_at__lte: monthEnd.toISOString(),
              },
            },
          });
        },
      },
      {
        label: "Leads Submitted",
        value: newSubmittedThisMonth?.count,
        routerLink: "/text-messages/",
        handleClick: () => {
          dispatch({
            type: "set",
            value: {
              filters: {
                convo_archived: "Active",
                last_lead_submitted_actual__gte: monthStart.toISOString(),
                last_lead_submitted_actual__lte: monthEnd.toISOString(),
              },
            },
          });
        },
      },
      {
        label: "Engaged Leads",
        value: newInboundsThisMonth?.count,
        routerLink: "/text-messages/",
        handleClick: () => {
          dispatch({
            type: "set",
            value: {
              filters: {
                convo_archived: "Active",
                last_human_inbound_time__gte: monthStart.toISOString(),
                last_human_inbound_time__lte: monthEnd.toISOString(),
              },
            },
          });
        },
      },
      {
        label: "Archived Leads",
        value: archivedLeadsThisMonth?.length,
        routerLink: "/reports/archived-leads/",
      },
      {
        label: "Credit Apps",
        value: creditAppsThisMonth.length,
        routerLink: "/credit-applications/",
      },
      {
        label: "Soft Pulls",
        value: softPullsThisMonth,
        routerLink: "/quick-qualifications/",
      },
      {
        label: "Trade-Ins",
        value: tradeinsThisMonth.length,
        routerLink: "/trades/",
      },
    ],
  };
}