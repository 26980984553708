import { useEffect, useState, useRef, useCallback } from "react";
import { leadsService } from "../services";

export default function usePipelineCardData(clientId: number | undefined) {
  const [loading, setLoading] = useState(true);
  const [workingPipeline, setWorkingPipeline] = useState<any>();
  const [pausedPipeline, setPausedPipeline] = useState<any>();
  const [deadGhostPipeline, setDeadGhostPipeline] = useState<any>();
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const fetchPipelineData = useCallback(async () => {
    if (!clientId) return;
    setLoading(true);
    const [working, paused, dead] = await Promise.all([
      leadsService.listWorkingPipeline({ clientId, pageSize: 1 }),
      leadsService.listPausedPipeline({ clientId, pageSize: 1 }),
      leadsService.listDeadGhostPipeline({ clientId, pageSize: 1 }),
    ]);
    setWorkingPipeline(working);
    setPausedPipeline(paused);
    setDeadGhostPipeline(dead);
    setLoading(false);
  }, [clientId]);

  useEffect(() => {
    fetchPipelineData();

    // Clear old interval
    if (intervalRef.current) clearInterval(intervalRef.current);

    intervalRef.current = setInterval(fetchPipelineData, 300000); // Every 5 minutes

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, [fetchPipelineData]);

  return {
    loading,
    workingPipeline,
    pausedPipeline,
    deadGhostPipeline,
  };
}
