import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
} from "react";
import { callOutline, mailOutline } from "ionicons/icons";
import {
  IonCol,
  IonRow,
  IonCard,
  IonIcon,
  IonText,
  IonCardHeader,
  IonCardTitle,
  IonLabel,
  IonCardContent,
  IonList,
  IonItem,
  IonBadge,
  IonRouterLink,
  IonGrid,
  IonSkeletonText,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import {
  TPage,
  UpcomingAppointmentsCard,
  UnreadNotificationsCard,
  TimeToCallCard,
  Leaderboard,
  UnassignedLeadsCountCard,
  SiteTranslator,
  SmsUsageStats,
  DashboardCard,
} from "../components";
import { AppContext } from "../context/AppContext";
import { LeadContext } from "../context/LeadContext";
import {
  usePipelineCardData,
  useTimePeriodCardData,
  useWaitingNeedsCallData,
} from "../hooks";
import {
  getTodayCardConfig,
  getPipelineCardConfig,
  getMonthCardConfig,
  util
} from "../core";

const Dashboard: React.FC = () => {
  const { state } = useContext(AppContext);
  const { dispatch } = useContext(LeadContext);
  const [date, setDate] = useState<Date>(new Date());
  const [month, setMonth] = useState<Date>(new Date());
  const clientId = state.selectedClientId;
  const leadAssignedTo = state.user.is_client_admin ? undefined : state.user.id;

  const incrementDate = () => {
    setDate((prevDate) => new Date(prevDate.getTime() + 24 * 60 * 60 * 1000));
  }

  const decrementDate = () => {
    setDate((prevDate) => new Date(prevDate.getTime() - 24 * 60 * 60 * 1000));
  }

  const incrementMonth = () => {
    setMonth(prevMonth => {
      const next = new Date(prevMonth);
      next.setMonth(prevMonth.getMonth() + 1);
      return next;
    });
  };
  
  const decrementMonth = () => {
    setMonth(prevMonth => {
      const next = new Date(prevMonth);
      next.setMonth(prevMonth.getMonth() - 1);
      return next;
    });
  };

  const isToday = useMemo(() => {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  }, [date]);

  const isCurrentMonth = useMemo(() => {
    const now = new Date();
    return (
      month.getMonth() === now.getMonth() &&
      month.getFullYear() === now.getFullYear()
    );
  }, [month]);  

  const {
    loading: loadingToday,
    newLeads: newLeadsToday,
    submittedLeads: newSubmittedLeadsToday,
    inboundLeads: newInboundsToday,
    archivedLeads: archivedLeadsToday,
    tradeins: tradeinsToday,
    creditApps: creditAppsToday,
    softPulls: softPullsToday,
    startPolling: startPollingToday,
    stopPolling: stopPollingToday,
    refetch: refetchTodayData,
  } = useTimePeriodCardData(clientId, leadAssignedTo, date);

  const {
    loading: loadingThisMonth,
    newLeads: newLeadsThisMonth,
    submittedLeads: newSubmittedThisMonth,
    inboundLeads: newInboundsThisMonth,
    archivedLeads: archivedLeadsThisMonth,
    tradeins: tradeinsThisMonth,
    creditApps: creditAppsThisMonth,
    softPulls: softPullsThisMonth,
    startPolling: startPollingMonth,
    stopPolling: stopPollingMonth,
    refetch: refetchMonthData,
  } = useTimePeriodCardData(clientId, leadAssignedTo, month, 'month');

  const {
    loading: loadingPipelines,
    workingPipeline,
    pausedPipeline,
    deadGhostPipeline,
  } = usePipelineCardData(clientId);

  const {
    loading: loadingWaiting,
    needsCall,
    waiting,
    usersNeedsCall,
    usersWaiting,
  } = useWaitingNeedsCallData(clientId, leadAssignedTo);

  const todayCardLoading = loadingToday;
  const pipelineCardLoading = loadingPipelines;
  const waitingCardLoading = loadingWaiting;

  useEffect(() => {
    refetchMonthData();
  }, [month, refetchMonthData]);

  useEffect(() => {
    refetchTodayData();
  }, [date, refetchTodayData]);

  useIonViewWillEnter(() => {
    startPollingToday();
    startPollingMonth();
  });

  useIonViewWillLeave(() => {
    stopPollingToday();
    stopPollingMonth();
  });

  const renderUserList = (o: any, listType: string) => {
    const keys: any = typeof o === "object" ? Object.keys(o) : [];
    if (!keys.length) {
      return (
        <IonItem lines='none'>
          <span role='img' aria-label='cool'>
            😎
          </span>{" "}
          &nbsp;No leads waiting.
        </IonItem>
      );
    }
    return keys.map((key: string) => {
      const keyPcs = key.split("::");
      const userFullName = keyPcs[1] !== "undefined" ? keyPcs[0] : "Unassigned";
      const userId = keyPcs[1] !== "undefined" ? keyPcs[1] : "Unassigned";
      return (
        <IonItem
          className='pointer'
          key={key}
          routerLink='/text-messages/'
          routerDirection='none'
          onClick={() => {
            const changes = {
              assigned_to: {
                id: userId,
                display: userFullName,
              },
              convo_archived: "Active",
            } as any;
            if (listType === "needsCall") {
              changes["needs_call"] = "Call Needed";
            } else if (listType === "waiting") {
              changes["last_message_type"] = "inbound";
            }
            dispatch({
              type: "set",
              value: {
                filters: { ...changes },
              },
            });
          }}
          detail
        >
          {userFullName}
          <IonBadge
            color={listType === "waiting" ? "secondary" : "orange"}
            slot='end'
          >
            {o[key]?.length}
          </IonBadge>
        </IonItem>
      );
    });
  };

  const todayCard = useMemo(() => {
    return getTodayCardConfig({
      date,
      isToday,
      loading: todayCardLoading,
      newLeadsToday,
      newSubmittedLeadsToday,
      newInboundsToday,
      archivedLeadsToday,
      creditAppsToday,
      softPullsToday,
      tradeinsToday,
      decrementDate,
      incrementDate,
      dispatch,
    });
  }, [
    date,
    isToday,
    todayCardLoading,
    newLeadsToday,
    newSubmittedLeadsToday,
    newInboundsToday,
    archivedLeadsToday,
    creditAppsToday,
    softPullsToday,
    tradeinsToday,
    dispatch,
  ]);

  const monthCard = useMemo(() => {
    return getMonthCardConfig({
      date: month,
      isCurrentMonth,
      loading: loadingThisMonth,
      newLeadsThisMonth,
      newSubmittedThisMonth,
      newInboundsThisMonth,
      archivedLeadsThisMonth,
      creditAppsThisMonth,
      softPullsThisMonth,
      tradeinsThisMonth,
      decrementMonth,
      incrementMonth,
      dispatch,
    });
  }, [
    month,
    isCurrentMonth,
    loadingThisMonth,
    newLeadsThisMonth,
    newSubmittedThisMonth,
    newInboundsThisMonth,
    archivedLeadsThisMonth,
    creditAppsThisMonth,
    softPullsThisMonth,
    tradeinsThisMonth,
    dispatch,
  ]);

  const pipelineCard = useMemo(() => {
    return getPipelineCardConfig({
      loading: pipelineCardLoading,
      workingPipeline,
      pausedPipeline,
      deadGhostPipeline,
      dispatch,
    });
  }, [
    pipelineCardLoading,
    workingPipeline,
    pausedPipeline,
    deadGhostPipeline,
    dispatch,
  ]);
  
  const colProps = {
    sizeSm: "12",
    sizeXs: "12",
    sizeMd: "6",
    sizeLg: "6",
    sizeXl: "6",
  }

  return (
    <TPage loading={false}>
      <IonGrid>
        <IonRow>
          <IonCol sizeSm='12' sizeMd='6'>
            <Leaderboard
              title='Sales Leaderboard'
              userType='assigned_to'
              limit={5}
              showSeeMore={true}
              selectedClientId={state.selectedClientId}
            />
          </IonCol>
          <IonCol sizeSm='12' sizeMd='6'>
            {!!state.selectedClient?.package?.included &&
              !!state.selectedClient?.package?.overage && (
                <SmsUsageStats selectedClientId={state.selectedClientId} />
              )}
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>Contact Support</IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                {/* Email Support */}
                <div className='dashboard-contact-details-container'>
                  <IonIcon icon={mailOutline} size='large' />
                  <IonText className='dashboard-contact-details'>
                    Email us at:
                    <a href='mailto:support@tecobi.com'>support@tecobi.com</a>
                  </IonText>
                </div>
                {/* Text Support */}
                {state?.user?.tecobi_lead_twilio_number && (
                  <div
                    className='dashboard-contact-details-container'
                    style={{ marginTop: "16px" }}
                  >
                    <IonIcon icon={callOutline} size='large' />
                    <IonText className='dashboard-contact-details'>
                      Text us at:
                      <a
                        href={`sms:${state?.user?.tecobi_lead_twilio_number}?body=Help TECOBI!`}
                      >
                        {util.formatPhoneNumber(
                          state?.user?.tecobi_lead_twilio_number
                        )}
                      </a>
                    </IonText>
                  </div>
                )}
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>
      </IonGrid>

      <IonGrid className='reports'>
        <IonRow>
          <IonCol {...colProps} style={{ height: 'fit-content' }}>
            <DashboardCard {...todayCard} />
          </IonCol>
          <IonCol {...colProps} style={{ height: 'fit-content' }}>
            <DashboardCard {...monthCard} />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol {...colProps} style={{ height: 'fit-content' }}>
            <DashboardCard {...pipelineCard} />
          </IonCol>
        </IonRow>
      </IonGrid>

      <IonGrid className='reports'>
        <IonRow>
          <IonCol sizeSm='12' sizeXs='12' sizeMd='6' sizeLg='6' sizeXl='6'>
            <IonCard>
              <IonCardHeader color='orange'>
                <IonCardTitle>
                  Customers Needing Call
                  <IonRouterLink
                    routerLink='/text-messages/'
                    routerDirection='root'
                  >
                    <IonBadge
                      className='pointer'
                      onClick={() => {
                        const changes = {
                          convo_archived: "Active",
                          needs_call: "Call Needed",
                        } as any;
                        dispatch({
                          type: "set",
                          value: {
                            filters: { ...changes },
                          },
                        });
                      }}
                    >
                      {needsCall?.count}
                    </IonBadge>
                  </IonRouterLink>
                </IonCardTitle>
              </IonCardHeader>
              <IonCardContent style={{ maxHeight: 300, overflow: "auto" }}>
                {waitingCardLoading ? (
                  Array.from(Array(3).keys()).map((it: any, i: number) => (
                    <IonItem key={i}>
                      <IonLabel>
                        <IonSkeletonText key={i} animated />
                      </IonLabel>
                    </IonItem>
                  ))
                ) : (
                  <IonList>
                    {renderUserList(usersNeedsCall, "needsCall")}
                  </IonList>
                )}
              </IonCardContent>
            </IonCard>
          </IonCol>
          <IonCol sizeSm='12' sizeXs='12' sizeMd='6' sizeLg='6' sizeXl='6'>
            <IonCard>
              <IonCardHeader color='secondary'>
                <IonCardTitle>
                  Customers Waiting for SMS
                  <IonRouterLink
                    routerLink='/text-messages/'
                    routerDirection='root'
                  >
                    <IonBadge
                      className='pointer'
                      onClick={() => {
                        const changes = {
                          convo_archived: "Active",
                          pause_date: "Unpaused",
                          last_message_type: "inbound",
                        } as any;
                        dispatch({
                          type: "set",
                          value: {
                            filters: { ...changes },
                          },
                        });
                      }}
                    >
                      {waiting?.count}
                    </IonBadge>
                  </IonRouterLink>
                </IonCardTitle>
              </IonCardHeader>
              <IonCardContent style={{ maxHeight: 300, overflow: "auto" }}>
                {waitingCardLoading ? (
                  Array.from(Array(3).keys()).map((it: any, i: number) => (
                    <IonItem key={i}>
                      <IonLabel>
                        <IonSkeletonText key={i} animated />
                      </IonLabel>
                    </IonItem>
                  ))
                ) : (
                  <IonList>{renderUserList(usersWaiting, "waiting")}</IonList>
                )}
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>
      </IonGrid>

      <IonGrid className='reports'>
        <IonRow>
          <UpcomingAppointmentsCard />
        </IonRow>
      </IonGrid>

      <TimeToCallCard />
      <UnassignedLeadsCountCard />
      <IonGrid className='reports'>
        <IonRow>
          <IonCol sizeSm='12' sizeXs='12' sizeMd='12' sizeLg='12' sizeXl='12'>
            <UnreadNotificationsCard />
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonItem>
        <SiteTranslator
          includedLanguages='es,en'
          id='google_translate_element'
          pageLanguage='en'
        />
      </IonItem>
    </TPage>
  );
};

export default Dashboard;
