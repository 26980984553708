import { useEffect, useRef, useState } from "react";
import { leadsService } from "../services";

export default function useWaitingNeedsCallData(clientId: number | undefined, leadAssignedTo: number | undefined) {
  const [loading, setLoading] = useState(true);
  const [needsCall, setNeedsCall] = useState<any>();
  const [waiting, setWaiting] = useState<any>();
  const [usersNeedsCall, setUsersNeedsCall] = useState<any>();
  const [usersWaiting, setUsersWaiting] = useState<any>();
  const intervalRef = useRef<any>(null);

  useEffect(() => {
    if (!clientId) return;
  
    const fetch = async () => {
      setLoading(true);
  
      const [needs, waits] = await Promise.all([
        leadsService.listNeedsCall({ clientId, pageSize: 500 }),
        leadsService.listWaiting({ clientId, pageSize: 500 }),
      ]);
  
      const needsMap: any = {};
      const waitingMap: any = {};
  
      needs.results.forEach((it: any) => {
        const key = `${it.assigned_to?.first_name} ${it.assigned_to?.last_name}::${it.assigned_to?.id}`;
        if (!(key in needsMap)) {
          needsMap[key] = needs.results.filter((o: any) => o.assigned_to?.id === it.assigned_to?.id);
        }
      });
  
      waits.results.forEach((it: any) => {
        const key = `${it.assigned_to?.first_name} ${it.assigned_to?.last_name}::${it.assigned_to?.id}`;
        if (!(key in waitingMap)) {
          waitingMap[key] = waits.results.filter((o: any) => o.assigned_to?.id === it.assigned_to?.id);
        }
      });
  
      setNeedsCall(needs);
      setWaiting(waits);
      setUsersNeedsCall(needsMap);
      setUsersWaiting(waitingMap);
      setLoading(false);
    };
  
    fetch();
    intervalRef.current = setInterval(fetch, 40000);
    return () => clearInterval(intervalRef.current);
  }, [clientId, leadAssignedTo]);
  
  return {
    loading,
    needsCall,
    waiting,
    usersNeedsCall,
    usersWaiting,
  };
}
